import { CloseCircleOutlined } from "@ant-design/icons";
import { LoginRequestDTO } from "@dto/session.dto";
import { Input, InputProps, InputRef } from 'antd';
import { ChangeEvent, FormEvent, forwardRef, useEffect, useReducer, useRef } from "react";
import { RoutePath } from "src/data/Routes";
import { useAppSelector } from "src/redux/hooks";
import logo from "../../assets/logo-xormon.png";
import { GLOB } from "../../util/Glob";
import "./Login.less";

type logInput = "username" | "password";

export interface InputItem extends InputProps {
  inputKey: logInput;
  value: string;
  errMsg: string;
  error: boolean;
};

interface InitState {
  username: InputItem;
  password: InputItem;
  error: string;
}

export enum act {
  SET = "set-input",
  EMPTY = "empty-input",
  SUB_ERR = "submit-error",
}

const ERROR_MSG = {
  EMPTY: '',
  NO_USER: "The Username field is required.",
  NO_PASS: "The Password field is required.",
  BOTH: "The Username and Password fields are required.",
} as const;

const initialState: InitState = {
  username: {
    inputKey: "username",
    type: "text",
    placeholder: "Username",
    autoComplete: "username",
    value: "",
    errMsg: ERROR_MSG.NO_USER,
    error: false
  },
  password: {
    inputKey: "password",
    type: "password",
    placeholder: "Password",
    autoComplete: "current-password",
    value: "",
    errMsg: ERROR_MSG.NO_PASS,
    error: false
  },
  error: ERROR_MSG.EMPTY
};

const reducer = (state: InitState, { type, input, error }: { type: act, input?: { inputKey: logInput, value?: string }, error?: string }): InitState => {
  const inputKey = input?.inputKey; /// just simplification of variable

  const setError = () => {
    if (!state.error || state.error === state[inputKey].errMsg)
      return "";

    if (state.error === ERROR_MSG.BOTH) {
      if (inputKey === state.username.inputKey)
        return ERROR_MSG.NO_PASS;

      return ERROR_MSG.NO_USER;
    }

    return state.error;
  }

  if (type === act.SET)
    return {
      ...state,
      [inputKey]: { ...state[inputKey], value: input.value, error: false },
      error: setError()
    };

  if (type === act.EMPTY) {
    if (state[inputKey].value || state.error == ERROR_MSG.BOTH)
      return state;

    return {
      ...state,
      error: state.error && (state.error == ERROR_MSG.NO_PASS || state.error == ERROR_MSG.NO_USER) && state.error !== state[inputKey].errMsg ? ERROR_MSG.BOTH : state[inputKey].errMsg,
      [inputKey]: { ...state[inputKey], error: true }
    }
  }

  if (type === act.SUB_ERR)
    return { ...initialState, error };
};

export function Login() {
  const appGlobals = useAppSelector(store => store.appGlobals);
  const [{ username, password, error }, dispatch] = useReducer(reducer, initialState);
  const userInputRef = useRef<InputRef>(null);
  const passInputRef = useRef<InputRef>(null);
  const demo = appGlobals.info?.backend?.demo;

  useEffect(() => {
    if (window.location.pathname.endsWith(RoutePath.LOGOUT))
      window.history.replaceState(null, "Xormon", GLOB.getPublicUrl() ? GLOB.getPublicUrl() : '/');

    userInputRef.current?.focus();
  }, [])

  useEffect(() => {
    if (demo) {
      initialState.password.value = initialState.username.value = 'xormon';
    }
  }, [demo])

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!password.value || !username.value) {
      if (!password.value) {
        dispatch({ type: act.EMPTY, input: { inputKey: password.inputKey } });
        passInputRef.current.focus();
      }

      if (!username.value) {
        dispatch({ type: act.EMPTY, input: { inputKey: username.inputKey } });
        userInputRef.current.focus();
      }

      return;
    }

    const login: LoginRequestDTO = { username: username.value, password: password.value };
    const response = await GLOB.authService.logIn(login);
    userInputRef.current?.focus();
    if (!GLOB.authService.isLoggedIn())
      dispatch({ type: act.SUB_ERR, error: response as string });

  };

  const handleChange = (inputKey: logInput) => ({ target: { value } }: ChangeEvent<HTMLInputElement>) => dispatch({ type: act.SET, input: { inputKey, value } });
  const handleBlur = (inputKey: logInput) => () => dispatch({ type: act.EMPTY, input: { inputKey } });

  return (
    <div className={`login-wrapper ${demo ? 'demo' : ''}`}>
      <div className="login-container">
        <div className="logo">
          <img src={logo} alt="xormon_logo" />
        </div>
        <div className="login-form-container">
          <h1 className="login-header">Login</h1>
          <form className="login-form" onSubmit={handleSubmit}>
            {[username, password].map(({ inputKey, errMsg, ...input }) =>
              <LoginInput
                key={inputKey}
                {...input}
                onChange={handleChange(inputKey)}
                onBlur={handleBlur(inputKey)}
                ref={inputKey === 'username' ? userInputRef : passInputRef}
              />
            )}
            <div className="form-item-wrapper">
              <button type="submit" className="login-form-button form-item">Sign in</button>
            </div>
          </form>
        </div>
      </div>
      {error &&
        <div className="login-error-wrapper">
          <div className="login-error-container">
            <CloseCircleOutlined className="login-error-icon" />
            <span className="login-error-text">{error}</span>
          </div>
        </div>
      }

      {!demo && appGlobals.info?.backend?.firstLogin && <div className="xm-demo" style={{ width: '25em' }}>
        Default system account credentials:
        <div>Username/password: xormon/xormon</div>
      </div>}
      {demo && <>
        <div className="xm-demo">
          Try the UI with a demo account:
          <div>Username/Password: xormon/xormon</div>
        </div>
        <div className="xm-demo">
          This is a feature preview demo.<br />
          For full list of supported devices and metrics go to:<br />
          <a href="https://www.lpar2rrd.com/demo.php" target="_blank">demo.lpar2rrd.com</a> - Server, DB, Cloud, Container<br />
          <a href="https://www.stor2rrd.com/demo.php" target="_blank">demo.stor2rrd.com</a> - Storage, SAN, LAN
        </div>
        <div className="xm-demo">
          Would you be interested in a live Webex session with one of our experts<br />
          to show you all the features of the product?<br />
          <a href="https://xormon.com/live_form.html" target="_blank">Apply here</a>
        </div>
      </>}

    </div>
  );
}

const LoginInput = forwardRef<InputRef, Omit<InputItem, 'errMsg' | 'inputKey'>>(function LoginInput({ error, ...rest }, ref) {
  const inputClass = error ? "form-input form-item form-input-error" : "form-input form-item";

  return (
    <label className="form-item-wrapper">
      <Input className={inputClass}
        {...rest}
        ref={ref}
      />
    </label>
  );
});