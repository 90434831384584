export enum RoutePath {
  ABOUT = '/about',
  ALERTING = '/alerting',
  BACKUP = '/backup',
  CLASS = '/class',
  CONFIGURATION = '/configuration',
  DEVICE = '/device',
  GET_PATH = '/getpath',
  GROUP = '/group',
  TYPE = '/type',
  //MONITORING = '/monitoring',
  CUSTOM_GROUPS = '/custom',
  DASHBOARD = '/dashboard',
  DASH_FAV = '/favorite',
  DASH_HEAT = '/heatmap',
  DEFAULT = '/default',
  DOCUMENTATION = '/documentation',
  DB = '/db',
  EVENTS = '/events',
  FOLDER = '/folder',
  GLOB_HS = '/glob-hs-summary',
  GROUPMANAGEMENT = '/group_management',
  HELP = '/help',
  LICENSE = '/license',
  LOGOUT = '/logout',
  NETWORK = '/network',
  PLATFORM = '/other',
  PLAYGROUND = '/playground',
  PROFILE = '/profile',
  RELOAD = '/reload',
  SERVER_GROUP = "/servergroup",
  SETTINGS = '/settings',
  SET_ALIAS = '/alias',
  SET_BACKEND = '/backend',
  SET_CG = '/cg',
  SET_DASH_MANAGE = '/dm',
  SET_DEVICE = '/dev',
  SET_HEATMAP_TIERS = '/ht',
  SET_IMPORT = '/import',
  SET_SELF_MONITORING = '/self_monitoring',
  SET_LOGS = '/logs',
  SET_OS_AGENT = '/os_agent',
  SET_PROXY = '/proxy',
  SET_SG = '/sg',
  SET_SERVER_GROUP = '/server-group',
  SGROUP = '/sgroup',
  //STORAGE_GROUP = '/sg',
  //STORAGE_TYPE = '/st',
  USERMANAGEMENT = '/user_management',
  LDAP = '/ldap',
  TOOLS = '/tools',
  PLATFORMLPAR = '/lpar',
  PUBLIC_API = "/public_api",

  // virtualization
  SERVER = '/server',
  HYPERV = '/hyperv',
  HUAWEI = '/huawei',
  LINUX = '/linux',
  NUTANIX_CFG = '/nutanix',
  ORACLEVM = '/oraclevm',
  OVIRT = '/ovirt',
  POWER_CFG = '/power',
  PROXMOX_CFG = '/proxmox',
  VMWARE_CFG = '/vmware',
  XEN = '/xen',
  IBMCLOUD = '/ibmcloud',

  // cloud
  CLOUD = '/cloud',
  CLOUDSTACK = '/cloudstack',
  AZURE_CFG = '/azure',
  AMAZON_CFG = '/aws',
  GCLOUD_CFG = '/gcloud',
  // container
  CONTAINER = '/container',
  DOCKER_CFG = '/docker',
  KUBERNETES_CFG = '/kubernetes',
  OPENSHIFT_CFG = '/openshift',
  // DB
  DATABASE = '/database',
  ORACLEDB = '/oracledb',
  POSTGRES = '/postgres',
  SQLSERVER = '/sqlserver',
  DB2 = '/db2',

  STORAGES = '/storages',
  STORAGE = '/storage',
  LAN_CFG = '/lan',
  SAN = '/san',
  SAN_NAV_CFG = '/san_nav',

  SEARCH = '/search',
  REPORTER = '/reporter',
  EXPORTER = '/exporter',
  TAGS = '/tags',
  SUPPORT = '/support',
  UPGRADE = '/upgrade',
  LOG_UI = '/ui-log',
  LOG_AUDIT = '/audit-log',
  LOG_SUPPORT = '/support-logs',

  MATCH_CMP = '/:cmp',
  MATCH_SECTION_CMP = '/:section/:cmp',
  // /settings/utilities/:subtype/:group?/:item?
  MATCH_SETTINGS_HELP = '/settings/help/:subtype',
  MATCH_GROUP_ONLY = ':group',
  // /:class/device/:type/:item?
  MATCH_DEVICE = '/:class/device/:type/:item',
  // /:class/type/:type/:item?
  MATCH_TYPE = '/:class/type/:type/:item',
  // /:class/group/:group/:type?/:item?
  MATCH_GROUP_TOP = '/:class/group/:group/:type',
  MATCH_ITEM_ONLY = ':item',
  MATCH_CLASS_TYPE_ITEM = '/:class/:type/:item',
  MATCH_SPLAT = '/*',
}

export type AllType = RoutePath.DEVICE | RoutePath.GROUP | RoutePath.TYPE

export function isAllType(path): path is AllType {
  switch (path) {
    case RoutePath.DEVICE:
    case RoutePath.GROUP:
    case RoutePath.TYPE:
      return true
    default:
      return false
  }
}

/**
 * Checks if HW types or devices in tree
 * @param allType
 * @returns true for tree of devices or groups
 */
export function isDevicesTree(allType: AllType) {
  return allType === RoutePath.DEVICE || allType === RoutePath.GROUP
}

const findDuplicates = (arr: string[]) => arr.filter((item, index) => arr.indexOf(item) != index);
const duplicates = findDuplicates(Object.values(RoutePath));
if (duplicates.length > 0) throw new Error('Routes are not unique: ' + duplicates);
