
/// 3rd party components (ant.design)
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Tree } from "antd";

/// glob functions & variables

/// interfaces
import { Device } from "@dto/storageGroups.dto";
import { DataNode } from "./interface";

interface Props {
  devices: Device[];
  selectAble: boolean;
  showIcon: boolean;
  withoutTitle?: boolean;
  removeDeviceFromPrewie?: (device: DataNode) => void;
}

const ExpandPreview = ({ devices, selectAble, showIcon, removeDeviceFromPrewie, withoutTitle }: Props) => {
  const treeData = MakeTreeData(devices, withoutTitle);

  return (
    <Tree
      treeData={treeData}
      className={showIcon ? 'xm-preview xm-preview-form' : 'xm-preview'}
      selectable={selectAble}
      titleRender={(device: DataNode) => {
        return (
          <div className="preview-title">
            {showIcon && <Button className='ant-btn-link hover-danger' icon={<DeleteOutlined />} onClick={() => removeDeviceFromPrewie(device)} />}
            <div>{device.title}</div>
          </div>
        );
      }}
    />
  );
};

export const MakeTreeData = (devices: Device[], withoutTitle = false) => {
  const result: DataNode[] = [];
  const makeTitle = (label: string, hwType: string) => (
    <>
      <span>{label}</span>
      {!withoutTitle && <span className='device-title-hw-type'>{`(${hwType.toUpperCase()})`}</span>}
    </>
  );

  devices.sort((a, b) => a.label.localeCompare(b.label)).forEach(device => {
    result.push({
      title: makeTitle(device.label, device.hw_type),
      key: device.label,
      isLeaf: true,
      hwType: device.hw_type,
      itemId: device.item_id,
      checked: false,
    });
  });

  return result;
};

export default ExpandPreview;