import { useThemeSwitcher } from "react-css-theme-switcher";

const DARK_MODE = 'dark-mode';

const dmColor = '#ddd';
const dmBgColor = '#333';
const dmBgColorLight = '#444';

export abstract class ThemeUtil {

  // user info is loaded later
  private static darkMode: boolean;

  static themeSwitcher: ReturnType<typeof useThemeSwitcher>['switcher'];

  static readonly NAMES = {
    dark: 'dark',
    light: 'light'
  } as const;

  static isDarkMode(): boolean {
    if (ThemeUtil.darkMode === undefined) {
      ThemeUtil.darkMode = JSON.parse(localStorage.getItem(DARK_MODE)) || false;
    }
    return ThemeUtil.darkMode;
  }

  static isThemeStored(): boolean {
    return localStorage.getItem(DARK_MODE) !== null;
  }

  static setDarkTheme(c: boolean) {
    ThemeUtil.darkMode = c;
    localStorage.setItem(DARK_MODE, JSON.stringify(c));
    ThemeUtil.themeSwitcher?.({ theme: c ? ThemeUtil.NAMES.dark : ThemeUtil.NAMES.light });
  }

  static getColor() {
    return ThemeUtil.isDarkMode() ? dmColor : undefined;
  }

  static getBgColor(lightBgColor?: string) {
    return ThemeUtil.isDarkMode() ? dmBgColor : lightBgColor;
  }

  static getBgColorLight() {
    return ThemeUtil.isDarkMode() ? dmBgColorLight : undefined;
  }
}