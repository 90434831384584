import { IMetric } from "@dto/architecture.dto";
import { BOX_TYPE, GRAPH_INTERVAL } from "@dto/constants/pageResponse.constants";
import { UNIT } from "@dto/constants/subsystemMetricsResponse.constants";
import { FindGraph } from "@dto/dashboard.dto";
import { Box, GraphBase, GraphInterval, GraphTablePropertyItem, Metric, RegroupGraph } from "@dto/pageResponse.dto";
import { TimeseriesTopOrder } from "@dto/timeseriesRequest.dto";
import { ItemParent, TimeseriesMetadata } from "@dto/timeseriesResponse.dto";
import { UnitUtil } from "@dto/util/UnitUtil";
import _ from "lodash";
import { Layout, LayoutAxis, PlotData, PlotlyHTMLElement } from "plotly.js";
import { createContext } from "react";
import Plot from "react-plotly.js";
import { GLOB } from "src/util/Glob";
import { TextUtil } from "src/util/TextUtil";
import { ThemeUtil } from "src/util/ThemeUtil";

export class PlotCmp extends Plot {
  el: PlotEl;
  resizeHandler: () => void;
}

interface PlotEl extends PlotlyHTMLElement {
  _fullLayout: Layout;
  _fullData: PlotData[];
}

/**
 * Common graph component properties
 */
export interface CommonGraphProps {
  availableMetrics?: IMetric[];
  tabName?: string;
  dashUrl?: string;
  colorMap?: Record<string, string>;
  dashboardGraphs?: FindGraph[];
  setDashboardGraphs: (fg: FindGraph[]) => void;
}

interface IKTM {
  key: string;
  uid: string;
  name: string;
  parent?: ItemParent;
  rendered: Record<string, Record<string, string | number>>;
  data: Record<string, TimeseriesMetadata>;
  updated: boolean;
  updateCount: number;
}

export interface PropsKTM {
  itemProps: Record<string, GraphTablePropertyItem>;
  separator: boolean;
}

/**
 * (K)omputed table metric row entry
 */
export class KTM<P extends PropsKTM = PropsKTM> implements IKTM {
  protected static readonly ITEM_OTHERS = 'Others';

  key: string = null;
  uid: string = null;
  name: string = null;
  parent?: ItemParent = null;
  metric?: string = null;
  rendered: Record<string, Record<string, string | number>> = {};
  data: Record<string, TimeseriesMetadata> = {};
  props: Partial<P> = {};
  updated: boolean = null;
  updateCount = 0;

  constructor(other?: IKTM) {
    if (!other) return;
    for (const key in other) {
      if (Object.hasOwn(other, key) && Object.hasOwn(this, key)) {
        this[key] = other[key];
      }
    }
  }

  isOthers() {
    return this.name === KTM.ITEM_OTHERS && this.uid == null;
  }
}

/**
 * Graph data for modal
 */
export interface GraphData {
  data: Partial<PlotData>[];
  tableData: KTM[];
  max: number;
  zoomTitle: string;
}

/**
 * Graph component state properties
 */
export interface GraphState {
  additionalYaxis: IMetric;
  peakMetric?: boolean;
  stacked?: boolean;
  order: TimeseriesTopOrder;
  sortMetric?: string;
  loadedItems: number;
  othersTooltip: string;
  othersCount: number;
  uuids: string[];
  hoverMode: 'closest' | 'x' | 'y' | 'x unified' | 'y unified' | false;
  tableRowKeys: string[];
  plotlyRef: PlotCmp;
  unitSize: string;
  startSec?: number;
  endSec?: number;
}

export enum GraphType {
  regular, modal, dashboard
}

export interface StartEnd {
  start: number;
  end: number;
}

export interface LayoutProps {
  type: GraphType;
  title?: string;
  box: {
    aggregated?: boolean;
    graphLabel?: string;
    max?: number;
    metrics: Metric[];
    minInterval?: number;
    uuids?: string[];
  };
  data?: {
    zoomTitle?: string;
  }
}
export class GraphProps implements LayoutProps {
  title?: string;
  /**
   * Filename for saving plot as img or csv
   */
  filename?: string;
  box: GraphBase;
  /**
   * Unix seconds
   */
  start: number;
  /**
   * Unix seconds
   */
  end: number;
  interval?: GraphInterval;
  common?: CommonGraphProps;
  type: GraphType;
  data?: GraphData;
  state?: GraphState;
  onLoadFinished?: () => void;

  static isZoomAllowed(box: LayoutProps['box']) {
    return box.minInterval !== 0;
  }
}

function removeWords(str: string, words: string[]) {
  return words.reduce((prev, word) => prev.replace(word, ''), str).trim();
}

/**
 * Returns metric title used in Y-axis of graph
 * @param graphBase common DTO graph properties
 * @returns
 */
export function metricTitle(metrics: Metric[]) {
  const capitalized = TextUtil.capitalize((metrics[0].unitLabel || metrics[0].unit || '').replaceAll('_', ' '));
  let titleWithMetrics = capitalized;

  if (metrics.length === 2) {
    const negativeMetric = metrics.find(m => m.order < 0);
    if (negativeMetric) {
      const positiveMetric = metrics.find(m => m.order >= 0);
      if (positiveMetric) {
        const words = capitalized.toLowerCase().split(' ');
        const dupes = negativeMetric.label.toLowerCase().split(' ').concat(positiveMetric.label.toLowerCase().split(' ')).reduce<string[]>((prev, current, index, array) => {
          if (array.lastIndexOf(current) !== index && !prev.includes(current)) prev.push(current);
          return prev;
        }, []);
        words.push(...dupes);
        titleWithMetrics = _.capitalize(removeWords(negativeMetric.label.toLocaleLowerCase(), words)) + '  -  '
          + capitalized + '  -  ' + _.capitalize(removeWords(positiveMetric.label.toLocaleLowerCase(), words));
      }
    }
  }

  return titleWithMetrics;
}

export const TICK_FORMAT_SI = '.3~s';
export function createYaxis(metrics: Metric[]): Partial<LayoutAxis> {
  return {
    fixedrange: true,
    gridcolor: ThemeUtil.getBgColorLight(),
    tickformat: UnitUtil.isBaseUnit(metrics[0].unit) ? '.3~r' : TICK_FORMAT_SI,
    title: {
      text: metricTitle(metrics),
      font: {
        size: 10
      },
      standoff: 10
    },
    rangemode: 'tozero',
  }
}

export function createPlotlyLayout(props: LayoutProps) {
  const initialLayout: Partial<Layout> = {
    autosize: true,
    font: {
      size: 10,
      color: ThemeUtil.getColor()
    },
    hoverlabel: {
      bgcolor: ThemeUtil.getBgColor("white"),
      font: { size: 11, color: ThemeUtil.getColor() },
      align: "left",
    },
    hovermode: 'closest',
    paper_bgcolor: ThemeUtil.getBgColor(),
    plot_bgcolor: ThemeUtil.getBgColor(),
    legend: {
      x: 1,
      xanchor: 'right',
      y: 1
    },
    margin: { t: 25, b: 20, l: 60, r: 10 },
    showlegend: false,
    title: {
      text: props.data?.zoomTitle || props.box.graphLabel || props.title,
    },
    xaxis: {
      fixedrange: !GraphProps.isZoomAllowed(props.box),
      gridcolor: ThemeUtil.getBgColorLight(),
      tickformatstops: [
        {
          dtickrange: [null, 1000 * 60 * 59],
          value: '%H:%M'
        },
        {
          dtickrange: [null, 1000 * 60 * 60 * 12],
          value: '%H'
        },
        {
          dtickrange: [null, 1000 * 60 * 60 * 24],
          value: '%a'
        },
        {
          dtickrange: [null, 1000 * 60 * 60 * 24 * 14],
          value: '%d'
        },
        {
          dtickrange: [null, 1000 * 60 * 60 * 24 * 180],
          value: '%b'
        }
      ],
      tickmode: 'auto',
      type: 'date',
      showticklabels: !!props.box.uuids?.length
    },
    yaxis: createYaxis(props.box.metrics),
  };

  if (GLOB.colorPalette) {
    initialLayout.colorway = GLOB.colorPalette;
  }

  if (props.box.metrics.some(m => m.unit === UNIT.percent)) {
    initialLayout.yaxis.range = [0, 101];
  }

  switch (props.type) {
    case GraphType.dashboard:
      initialLayout.margin = { b: 15, l: 15, r: 0, t: 0 };
      initialLayout.yaxis.automargin = true;
      initialLayout.title = null;
      initialLayout.yaxis.title = null;
      initialLayout.yaxis.tickformat = TICK_FORMAT_SI;
      initialLayout.xaxis.fixedrange = true;
      break;
    case GraphType.regular:
      initialLayout.height = 250;
      break;
    default:
      initialLayout.height = window.innerHeight * 0.6;
  }

  return initialLayout;
}

/******************* REGROUP ******************************/

export class RegroupedGraphBox implements Box {
  type = BOX_TYPE.RegroupedGraphBox;
  interval: GraphInterval;
  graphs: RegroupGraph[];
  url: string;
}

export const RegroupedGraphIntervalLabel = {
  [GRAPH_INTERVAL.day]: 'Daily',
  [GRAPH_INTERVAL.week]: 'Weekly',
  [GRAPH_INTERVAL.month]: 'Monthly',
  [GRAPH_INTERVAL.year]: 'Yearly'
};

export interface RegroupProps {
  /**
   * Zoom start/end in unix seconds
   */
  readonly zoomRange: Readonly<StartEnd>;
  setZoomRange(range: StartEnd): void;
  /**
   * Keys of graph items hidden in plot
   */
  readonly hiddenItems: readonly string[];
  setHiddenItems(itemKeys: string[]): void;
}

export const RegroupContext = createContext<RegroupProps>({
  zoomRange: undefined,
  setZoomRange: () => undefined,
  hiddenItems: [],
  setHiddenItems: () => undefined
});

