import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Form, Select } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { useContext, useEffect } from 'react';
import { isClassLPAR } from 'src/data/Class';
import { invalidHwProps, RuleContext } from '../Rule';
import { dependenciesMaker } from '../children/TagList';
import { AllItemsContext } from '../comp/AllItems';
import { AllTagsContext } from '../comp/AllTags';
import TagSelect from '../comp/TagSelect';
import { makePreviewApiData } from '../comp/preview/CgPreviewHandler';
import { usePreviewModal } from '../comp/preview/Preview';
import { Option } from "../hook/useParentTypes";

function TagList({ parentTypes }: { parentTypes: Option[] }) {
  const { cls, subsystem: subsys, hw_type } = useContext(AllItemsContext);
  const { fetchStarted, startFetch } = useContext(AllTagsContext);
  const { ruleIndex, parents: { tags }, filterByParents, linuxExclude } = useContext(RuleContext);
  const invalidBaseProps = invalidHwProps({ cls, hw_type, subsystem: subsys });
  const isLPAR = isClassLPAR(cls);
  const [showPreview, contextHolder] = usePreviewModal(linuxExclude);

  useEffect(() => {
    if (!fetchStarted)
      startFetch();
  }, []);

  const handleRemove = (remove: () => void) => () => {
    remove();
    filterByParents();
  }

  const handlePreview = (subsystem: string, tag: string) => () => showPreview({
    cls,
    subsystem,
    hw_type,
    parent: true,
    data: makePreviewApiData(cls, hw_type, subsystem, undefined, undefined, [tag], linuxExclude),
  });

  const getDependencies = dependenciesMaker(ruleIndex, true);
  const getDependenciesSubsystem = dependenciesMakerSubsystem(ruleIndex);
  const noSameTag = sameTagRuleMaker(tags);

  return <>
    <Form.List name={[ruleIndex, 'parents', 'tags']} >
      {(tagsItems, { remove }) => {
        return (
          <div className='xm-rule-filter-tag'>
            {tagsItems.map(({ key, name }, indx) => (
              <span className='xm-tag-item' key={`parent-tag-list-${key}`}>
                <Form.Item
                  name={[name, 'subsystem']}
                  noStyle
                  dependencies={getDependencies(indx, tagsItems.length)}
                  rules={[
                    { required: true },
                  ]}
                >
                  <Select
                    className='xm-tag-subsystem'
                    placeholder='Subsystem'
                    size='small'
                    options={parentTypes}
                    onChange={() => filterByParents()}
                    getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
                  />
                </Form.Item>
                <span className='xm-tag-item-container'>
                  <Form.Item
                    name={[name, 'tag_id']}
                    help={<></>}
                    dependencies={[...getDependencies(indx, tagsItems.length), ...getDependenciesSubsystem(indx, tagsItems.length)]}
                    rules={[
                      { required: true },
                      noSameTag(indx)
                    ]}
                  >
                    <TagSelect selectProps={{ className: 'xm-tag-select', disabled: !tags[name].subsystem }} />
                  </Form.Item>
                  <Button
                    disabled={invalidBaseProps || !tags[name].tag_id}
                    size='small'
                    icon={<EyeOutlined />}
                    onClick={handlePreview(tags[name].subsystem, tags[name].tag_id)}
                  />
                </span>
                <Button
                  size='small'
                  className={invalidBaseProps || tags.length < 2 ? 'xm-disabled-delete' : 'hover-danger'}
                  disabled={invalidBaseProps || tags.length < 2}
                  type='link'
                  title='Remove tag'
                  icon={<DeleteOutlined onClick={handleRemove(() => remove(name))} />} />
              </span>
            ))}
          </div>
        )
      }}
    </Form.List >
    {contextHolder}
  </>;
}

export const dependenciesMakerSubsystem = (ruleIndex: string | number) => (indx: number, length: number) => {
  const deps: NamePath<(string | number)[]>[] = [];
  const start = (typeof ruleIndex != 'number') ? [ruleIndex, 'parents'] : ['rules', ruleIndex, 'parents'];

  for (let i = 0; i < length; i++) {
    deps.push([...start, 'tags', i, 'subsystem']);
  }

  return deps;
};

const sameTagRuleMaker = (tags: {
  tag_id: string;
  tag: string;
  subsystem: string;
}[]) => (tagIndx: number) => {
  const validator = (_, val: string) => {
    if (tags.some((tag, indx) => tag.tag_id == val && indx !== tagIndx && tags[tagIndx].subsystem == tag.subsystem))
      return Promise.reject(new Error('Tag is already in use with the same subsystem'));

    return Promise.resolve();
  }

  return { validator }
};

export default TagList;