import { BasicResponseDTO } from './basicResponse.dto';
import { parseBool } from './util/util';

class GlobKeyTypes {
  readonly SNMP_TRAP_HOST = '';
  readonly SNMP_COMMUNITY = '';
  readonly TOKEN_EXPIRATION = 0 // minutes, 0 = infinite token validity
  readonly TOP_ITEMS = 0;
  readonly VMWARE_SDK = 0;
  readonly PUBLIC_URL = '';
  readonly DATA_RETENTION = 365;  // days
  readonly REMOVE_FROM_MENU = 30; // days
  readonly LKEY: string;
  readonly CONF_CHANGES_RETENTION: 30; // days
  readonly DEFAULT_TIMEZONE: ''; // timezone
}

class AlertingKeyTypes {
  readonly NAGIOS = false;
  readonly IS_SCRIPT = false;
  readonly SCRIPT = '';
  readonly INCLUDE_GRAPHS = 0;
  readonly REPEAT_TIME = 0;
  readonly PEAK_TIME = 0;
  readonly XORMON_URL = '';
  readonly ALARMONE_URL = '';
  readonly SEND_PER_ITEM = false;
  readonly HISTORY_RETENTION = 14; // days
}

class SmtpKeyTypes {
  readonly HOST = '';
  readonly USER = '';
  readonly PASS = '';
  readonly PORT = '';
  readonly ENCRYPTION = ''
  readonly MAIL_FROM = '';
}

class ServicenowKeyTypes {
  readonly INSTANCE = '';
  readonly USER = '';
  readonly PASS = '';
  readonly TYPE = '';
  readonly CLASS = '';
  readonly CUSTOM = '';
}

class CertKeyTypes {
  readonly KEY = '';
  readonly CRT = '';
}

class LdapKeyTypes {
  readonly TIMEOUT_SEC = 0;
  readonly PAGE_SIZE = 0;
}

const ALERTING_ = 'ALERTING_';
const GLOB_ = 'GLOB_';
const SMTP_ = 'SMTP_';
const CERT_ = 'CERT_';
const LDAP_ = 'LDAP_';
const SERVICENOW_ = 'SERVICENOW_';

export class SettingKey<T = Record<string, any>> {

  static readonly ALERTING = new SettingKey(ALERTING_, new AlertingKeyTypes());
  static readonly GLOB = new SettingKey(GLOB_, new GlobKeyTypes());
  static readonly SMTP = new SettingKey(SMTP_, new SmtpKeyTypes());
  static readonly LDAP = new SettingKey(LDAP_, new LdapKeyTypes());
  static readonly SERVICENOW = new SettingKey(SERVICENOW_, new ServicenowKeyTypes());

  readonly PREFIX: string;
  protected readonly keyTypes: T;

  private constructor(prefix: string, keyTypes: T) {
    this.PREFIX = prefix;
    this.keyTypes = keyTypes;
  }

  private getType(propertyName: string) {
    return typeof this.keyTypes[propertyName.substring(propertyName.startsWith(this.PREFIX) ? this.PREFIX.length : 0)];
  }

  /**
   * Helper to get name of property
   * @param propertyName property key
   * @returns name of property
   */
  get(propertyName: keyof T) {
    return this.PREFIX + (propertyName as string) as keyof SettingsItem;
  }

  // getValue(propertyName: keyof T, settings: SettingsItem){
  //   const key = this.get(propertyName);
  //   const value:string = settings[key];
  //   if(value==null) return value;
  //   switch(this.getType(key)){
  //     case 'boolean':
  //       return parseBool(value);
  //     case 'number':
  //       return parseInt(value);
  //     default:
  //       return value;
  //   }
  // }


  /**
   * Changes value types according to keyTypes
   * @param settings object with values to be re-typed
   */
  reType(settings: SettingsItem) {
    Object.entries(settings).forEach(([key, value]) => {
      if (value != null) {
        switch (this.getType(key)) {
          case 'boolean':
            settings[key] = parseBool(value);
            break;
          case 'number':
            settings[key] = parseInt(value);
            break;
        }
      }
    });
  }
}

// type TypedSetting<P extends string, T> = { [K in (string & keyof T) as `${P}${K}`]: T[K] };
// type SettingsItemTyped = Partial<TypedSetting<typeof ALERTING_, AlertingKeyTypes> | TypedSetting<typeof GLOB_, GlobKeyTypes>>;
//let test: SettingsItemTyped = { ALERTING_INCLUDE_GRAPHS: 2, GLOB_DATA_RETENTION: 30, };
//const val = test.ALERTING_INCLUDE_GRAPHS;

export type SettingsItem = Partial<Record<`${typeof ALERTING_}${keyof AlertingKeyTypes}` | `${typeof GLOB_}${keyof GlobKeyTypes}`
  | `${typeof SMTP_}${keyof SmtpKeyTypes}` | `${typeof CERT_}${keyof CertKeyTypes}` | `${typeof LDAP_}${keyof LdapKeyTypes}`
  | `${typeof SERVICENOW_}${keyof ServicenowKeyTypes}`, string>>;

export class SettingsResponseDTO extends BasicResponseDTO {
  data: SettingsItem;
}

export class MailTestDTO {
  mail: string;
}
