import { SettingOutlined, UserOutlined } from '@ant-design/icons';
import { ClassKey } from "@const/class.constants";
import { HwTypeKey } from '@dto/constants/hwTypeKey.constants';
import { Modal } from 'antd';
import * as _ from 'lodash-es';
import { Suspense, lazy } from 'react';
import { Spinner } from 'src/component/spinner/Spinner';
import { BasePage } from 'src/content/basePage/BasePage';
import BellIcon from 'src/content/globalHealthStatus/BellIcon';
import { LicenseBadge } from 'src/content/help/license/LicenseBadge';
import Search from 'src/content/search/Search';
import cfgTracker, { CFGTrackerWithTabs } from 'src/content/tools/confChanges/CFGTracker';
import { MenuNode } from '../model/MenuNode';
import { API_URL } from './Api';
import { RoutePath } from './Routes';

const CFGTracker = cfgTracker(CFGTrackerWithTabs);

const About = lazy(() => import('src/content/help/about/About'))
const Alerting = lazy(() => import('../content/tools/alerting/Alerting'))
const Aliases = lazy(() => import('src/content/configuration/application/Aliases/Aliases'))
const ApplicationCfg = lazy(() => import('../content/configuration/application/ApplicationCfg'))
const CustomGroups = lazy(() => import('../content/configuration/cg/CustomGroups'))
const DashboardManagement = lazy(() => import('src/content/configuration/dashboardManage/DashboardManagement'));
const Exporter = lazy(() => import('../content/tools/exporter/Exporter'))
const HeatmapTierHome = lazy(() => import('../content/configuration/heatmapTiers/HeatmapTierHome'))
const Import = lazy(() => import('src/content/configuration/application/Import/Import'))
const License = lazy(() => import('src/content/help/license/License'))
const Management = lazy(() => import('../content/configuration/management/management'))
const MissingTech = lazy(() => import('src/content/configuration/device/MissingTech'))
const ProfileTabs = lazy(() => import('src/content/userProfile/ProfileTabs'));
const ProxyAgent = lazy(() => import('src/content/configuration/application/ProxyAgent/ProxyAgent'))
const Reporter = lazy(() => import('../content/tools/reporter/components/Reporter'))
const StorageGroups = lazy(() => import('../content/configuration/sg/StorageGroups'))
const Support = lazy(() => import('src/content/help/support/Support'))
const Tag = lazy(() => import('src/content/tools/tag/Tag'))
const UniversalGroups = lazy(() => import('src/content/configuration/device/serverGroups/UniversalGroups'));
const UpgradeApp = lazy(() => import('src/content/help/upgrade/UpgradeApp'))

const BackupHostCfg = lazy(() => import('src/content/configuration/device/stor/BackupHostCfg'));
const LanHostCfg = lazy(() => import('src/content/configuration/device/stor/LanHostCfg'))
const SanHostCfg = lazy(() => import('src/content/configuration/device/stor/SanHostCfg'))
const StorageHostCfg = lazy(() => import('src/content/configuration/device/stor/StorageHostCfg'))

const Amazon = lazy(() => import('src/content/configuration/device/lpar/cloud/Amazon'))
const Azure = lazy(() => import('../content/configuration/device/lpar/cloud/Azure'))
const Db2 = lazy(() => import('src/content/configuration/device/lpar/db/Db2'))
const Docker = lazy(() => import('../content/configuration/device/lpar/container/Docker'))
const Gcloud = lazy(() => import('src/content/configuration/device/lpar/cloud/Gcloud'))
const IBMCloud = lazy(() => import('src/content/configuration/device/lpar/cloud/IBMCloud'));
const Kubernetes = lazy(() => import('../content/configuration/device/lpar/container/Kubernetes'))
const Nutanix = lazy(() => import('src/content/configuration/device/lpar/server/Nutanix'))
const OpenShift = lazy(() => import('src/content/configuration/device/lpar/container/OpenShift'))
const Oracle = lazy(() => import('src/content/configuration/device/lpar/db/Oracle'))
const OracleVM = lazy(() => import('src/content/configuration/device/lpar/server/OracleVM'))
const OSagent = lazy(() => import('src/content/configuration/device/lpar/server/OSagent'))
const Postgres = lazy(() => import('../content/configuration/device/lpar/db/Postgres'))
const Power = lazy(() => import('../content/configuration/device/lpar/server/power/Power'))
const Proxmox = lazy(() => import('../content/configuration/device/lpar/server/Proxmox'))
const RHVoVirt = lazy(() => import('src/content/configuration/device/lpar/server/RHVoVirt'))
const SqlServer = lazy(() => import('src/content/configuration/device/lpar/db/SqlServer'))
const VMware = lazy(() => import('../content/configuration/device/lpar/server/VMware'))

const Pie = lazy(() => import('../content/playground/pie'))
const Sunburst = lazy(() => import('../content/playground/sunburst'))
const Plot = lazy(() => import('src/content/playground/plot'));

export function resetMenuItemsRight() {
  menuItemsRight = [..._.cloneDeep(menuItemsRightTemplate)];
}
export let menuItemsRight: MenuNode[];
const menuItemsRightTemplate: readonly Readonly<MenuNode>[] = [
  {
    key: RoutePath.SEARCH,
    title: 'Search',
    path: RoutePath.SEARCH,
    component: <Search />,
    children: [],
    isLeaf: true
  },
  {
    key: RoutePath.GLOB_HS,
    icon: <BellIcon />,
    path: RoutePath.GLOB_HS,
    isLeaf: true
  },
  {
    key: RoutePath.SETTINGS,
    title: 'Settings',
    icon: <LicenseBadge hostcfg={true} license={true}><SettingOutlined /></LicenseBadge>,
    path: RoutePath.SETTINGS,
    children: [
      {
        key: RoutePath.CONFIGURATION,
        title: 'Configuration',
        path: RoutePath.CONFIGURATION,
        children: [
          {
            key: RoutePath.SET_DEVICE,
            title: <LicenseBadge offset={[3, 0]} hostcfg={true} license={false}>Device</LicenseBadge>,
            titleText: 'Device',
            path: RoutePath.SET_DEVICE,
            isLeaf: false,
            adminFeature: 'deviceConfig',
            children: [
              {
                key: RoutePath.STORAGE,
                title: 'Storage',
                path: RoutePath.STORAGE,
                isLeaf: true,
                component: (
                  <Suspense fallback={<Spinner />}>
                    <StorageHostCfg />
                  </Suspense>
                ),
                classType: ClassKey.STORAGE
              },
              {
                key: RoutePath.SERVER,
                title: 'Server Virtualization',
                path: RoutePath.SERVER,
                isLeaf: false,
                children: [
                  {
                    key: RoutePath.POWER_CFG,
                    title: 'IBM Power Systems',
                    path: RoutePath.POWER_CFG,
                    component: (
                      <Suspense fallback={<Spinner />}>
                        <Power />
                      </Suspense>
                    ),
                    isLeaf: true,
                    hwType: HwTypeKey.POWER
                  },
                  {
                    key: RoutePath.VMWARE_CFG,
                    title: 'VMware',
                    path: RoutePath.VMWARE_CFG,
                    component: (
                      <Suspense fallback={<Spinner />}>
                        <VMware />
                      </Suspense>
                    ),
                    isLeaf: true,
                    hwType: HwTypeKey.VMWARE
                  },
                  {
                    key: RoutePath.NUTANIX_CFG,
                    title: 'Nutanix',
                    path: RoutePath.NUTANIX_CFG,
                    component: (
                      <Suspense fallback={<Spinner />}>
                        <Nutanix />
                      </Suspense>
                    ),
                    isLeaf: true,
                    hwType: HwTypeKey.NUTANIX
                  },
                  {
                    key: RoutePath.PROXMOX_CFG,
                    title: 'Proxmox',
                    path: RoutePath.PROXMOX_CFG,
                    component: (
                      <Suspense fallback={<Spinner />}>
                        <Proxmox />
                      </Suspense>
                    ),
                    isLeaf: true,
                    hwType: HwTypeKey.PROXMOX
                  },
                  {
                    key: RoutePath.ORACLEVM,
                    title: 'Oracle VM',
                    path: RoutePath.ORACLEVM,
                    component: (
                      <Suspense fallback={<Spinner />}>
                        <OracleVM />
                      </Suspense>
                    ),
                    isLeaf: true,
                    hwType: HwTypeKey.ORACLEVM,
                  },
                  {
                    key: RoutePath.OVIRT,
                    title: 'RHV/oVirt',
                    path: RoutePath.OVIRT,
                    component: (
                      <Suspense fallback={<Spinner />}>
                        <RHVoVirt />
                      </Suspense>
                    ),
                    isLeaf: true,
                    hwType: HwTypeKey.OVIRT,
                  },
                  {
                    key: RoutePath.HUAWEI,
                    title: 'Huawei FusionCompute',
                    path: RoutePath.HUAWEI,
                    component: (
                      <Suspense fallback={<Spinner />}>
                        <MissingTech technoName="Huawei FusionCompute" />
                      </Suspense>
                    ),
                    isLeaf: true,
                    hwType: HwTypeKey.HUAWEI,
                  },
                  {
                    key: RoutePath.XEN,
                    title: 'XenServer',
                    path: RoutePath.XEN,
                    component: (
                      <Suspense fallback={<Spinner />}>
                        <MissingTech technoName="XenServer" />
                      </Suspense>
                    ),
                    isLeaf: true,
                    hwType: HwTypeKey.XEN,
                  },
                  {
                    key: RoutePath.SET_OS_AGENT,
                    title: 'OS Agent',
                    path: RoutePath.SET_OS_AGENT,
                    component: <Suspense fallback={<Spinner />}>
                      <OSagent />
                    </Suspense>,
                    isLeaf: true,
                    hwType: HwTypeKey.LINUX,
                    treeOnly: true,
                  },
                  {
                    key: RoutePath.SET_SERVER_GROUP,
                    title: 'Linux Groups',
                    path: RoutePath.SET_SERVER_GROUP,
                    component: (
                      <Suspense fallback={<Spinner />}>
                        <UniversalGroups
                          hwTypes={[{ hwType: "linux", subsystem: "server", class: ClassKey.VIRTUALIZATION }]}
                          label='Linux'
                        />
                      </Suspense>
                    ),
                    isLeaf: true,
                    hwType: HwTypeKey.LINUX,
                  },
                ],
              },
              {
                key: RoutePath.CLOUD,
                title: 'Cloud',
                path: RoutePath.CLOUD,
                isLeaf: false,
                children: [
                  {
                    key: RoutePath.AMAZON_CFG,
                    title: 'Amazon Web Services',
                    path: RoutePath.AMAZON_CFG,
                    component: (
                      <Suspense fallback={<Spinner />}>
                        <Amazon />
                      </Suspense>
                    ),
                    isLeaf: true,
                    hwType: HwTypeKey.AWS
                  },
                  {
                    key: RoutePath.AZURE_CFG,
                    title: 'Microsoft Azure',
                    path: RoutePath.AZURE_CFG,
                    component: (
                      <Suspense fallback={<Spinner />}>
                        <Azure />
                      </Suspense>
                    ),
                    isLeaf: true,
                    hwType: HwTypeKey.AZURE
                  },
                  {
                    key: RoutePath.GCLOUD_CFG,
                    title: 'Google Cloud',
                    path: RoutePath.GCLOUD_CFG,
                    component: (
                      <Suspense fallback={<Spinner />}>
                        <Gcloud />
                      </Suspense>
                    ),
                    isLeaf: true,
                    hwType: HwTypeKey.GCLOUD
                  },
                  {
                    key: RoutePath.IBMCLOUD,
                    title: 'IBM Cloud',
                    path: RoutePath.IBMCLOUD,
                    component: (
                      <Suspense fallback={<Spinner />}>
                        <IBMCloud />
                      </Suspense>
                    ),
                    isLeaf: true,
                    hwType: HwTypeKey.IBMCLOUD,
                  },
                  {
                    key: RoutePath.CLOUDSTACK,
                    title: 'Apache CloudStack',
                    path: RoutePath.CLOUDSTACK,
                    component: (
                      <Suspense fallback={<Spinner />}>
                        <MissingTech technoName="Apache CloudStack" />
                      </Suspense>
                    ),
                    isLeaf: true,
                    hwType: HwTypeKey.CLOUDSTACK
                  },
                ],
              },
              {
                key: RoutePath.CONTAINER,
                title: 'Container',
                path: RoutePath.CONTAINER,
                isLeaf: false,
                children: [
                  {
                    key: RoutePath.KUBERNETES_CFG,
                    title: 'Kubernetes',
                    path: RoutePath.KUBERNETES_CFG,
                    component: (
                      <Suspense fallback={<Spinner />}>
                        <Kubernetes />
                      </Suspense>
                    ),
                    isLeaf: true,
                    hwType: HwTypeKey.KUBERNETES
                  },
                  {
                    key: RoutePath.OPENSHIFT_CFG,
                    title: 'Red Hat OpenShift',
                    path: RoutePath.OPENSHIFT_CFG,
                    component: (
                      <Suspense fallback={<Spinner />}>
                        <OpenShift />
                      </Suspense>
                    ),
                    isLeaf: true,
                    hwType: HwTypeKey.OPENSHIFT
                  },
                  {
                    key: RoutePath.DOCKER_CFG,
                    title: 'Docker',
                    path: RoutePath.DOCKER_CFG,
                    component: (
                      <Suspense fallback={<Spinner />}>
                        <Docker />
                      </Suspense>
                    ),
                    isLeaf: true,
                    hwType: HwTypeKey.DOCKER
                  },
                ],
              },
              {
                key: RoutePath.DATABASE,
                title: 'Database',
                path: RoutePath.DATABASE,
                isLeaf: false,
                children: [
                  {
                    key: RoutePath.ORACLEDB,
                    title: 'Oracle',
                    path: RoutePath.ORACLEDB,
                    component: (
                      <Suspense fallback={<Spinner />}>
                        <Oracle />
                      </Suspense>
                    ),
                    isLeaf: true,
                    hwType: HwTypeKey.ORACLEDB
                  },
                  {
                    key: RoutePath.POSTGRES,
                    title: 'PostgreSQL',
                    path: RoutePath.POSTGRES,
                    component: (
                      <Suspense fallback={<Spinner />}>
                        <Postgres />
                      </Suspense>
                    ),
                    isLeaf: true,
                    hwType: HwTypeKey.POSTGRES
                  },
                  {
                    key: RoutePath.SQLSERVER,
                    title: 'Microsoft SQL Server',
                    path: RoutePath.SQLSERVER,
                    component: <Suspense fallback={<Spinner />}>
                      <SqlServer />
                    </Suspense>,
                    isLeaf: true,
                    hwType: HwTypeKey.SQLSERVER
                  },

                  {
                    key: RoutePath.DB2,
                    title: 'IBM Db2',
                    path: RoutePath.DB2,
                    component: <Suspense fallback={<Spinner />}>
                      <Db2 />
                    </Suspense>,
                    isLeaf: true,
                    hwType: HwTypeKey.DB2
                  },
                ],
              },
              {
                key: RoutePath.NETWORK,
                title: 'Network',
                path: RoutePath.NETWORK,
                isLeaf: false,
                children: [
                  {
                    key: RoutePath.SAN,
                    title: 'SAN',
                    path: RoutePath.SAN,
                    classType: ClassKey.SAN,
                    component: (
                      <Suspense fallback={<Spinner />}>
                        <SanHostCfg />
                      </Suspense>
                    ),
                    isLeaf: true,
                  },
                  {
                    key: RoutePath.LAN_CFG,
                    title: 'LAN',
                    path: RoutePath.LAN_CFG,
                    classType: ClassKey.LAN,
                    component: (
                      <Suspense fallback={<Spinner />}>
                        <LanHostCfg />
                      </Suspense>
                    ),
                    isLeaf: true,
                  },
                  // {
                  //   key: 'set-san-nav-cfg',
                  //   title: 'SANnav',
                  //   path: RoutePath.SAN_NAV_CFG,
                  //   component: (
                  //     <Suspense fallback={<Spinner />}>
                  //       <Azure />
                  //     </Suspense>
                  //   ),
                  //   isLeaf: true,
                  // },
                ],
              },
              {
                key: RoutePath.BACKUP,
                title: 'Backup',
                path: RoutePath.BACKUP,
                classType: ClassKey.BACKUP,
                isLeaf: true,
                component: <Suspense fallback={<Spinner />}>
                  <BackupHostCfg />
                </Suspense>,
              },
            ],
          },
          MenuNode.getDivider('cfg-div0', false),
          {
            key: RoutePath.SET_DASH_MANAGE,
            title: 'Dashboard',
            path: RoutePath.SET_DASH_MANAGE,
            component: (
              <Suspense fallback={<Spinner />}>
                <DashboardManagement />
              </Suspense>
            ),
            isLeaf: true,
          },
          {
            key: RoutePath.SET_CG,
            title: 'Custom Group',
            path: RoutePath.SET_CG,
            component: (
              <Suspense fallback={<Spinner />}>
                <CustomGroups />
              </Suspense>
            ),
            isLeaf: true,
          },
          {
            key: RoutePath.SET_SG,
            title: 'Storage Group',
            path: RoutePath.SET_SG,
            component: (
              <Suspense fallback={<Spinner />}>
                <StorageGroups />
              </Suspense>
            ),
            isLeaf: true,
          },
          {
            key: RoutePath.SET_HEATMAP_TIERS,
            title: 'Heatmap Tier',
            path: RoutePath.SET_HEATMAP_TIERS,
            component: (
              <Suspense fallback={<Spinner />}>
                <HeatmapTierHome />
              </Suspense>
            ),
            adminFeature: 'heatmapTiers',
            isLeaf: true,
          },
          MenuNode.getDivider('cfg-div1', true),
          {
            key: RoutePath.SET_ALIAS,
            title: 'Alias',
            path: RoutePath.SET_ALIAS,
            component: (
              <Suspense fallback={<Spinner />}>
                <Aliases />
              </Suspense>
            ),
            isLeaf: true,
            treeOnly: true,
            adminOnly: true,
          },
          {
            key: RoutePath.SET_IMPORT,
            title: 'Import',
            path: RoutePath.SET_IMPORT,
            component: (
              <Suspense fallback={<Spinner />}>
                <Import />
              </Suspense>
            ),
            isLeaf: true,
            treeOnly: true,
            adminOnly: true
          },
          {
            key: RoutePath.SET_PROXY,
            title: 'Proxy',
            path: RoutePath.SET_PROXY,
            component: (
              <Suspense fallback={<Spinner />}>
                <ProxyAgent />
              </Suspense>
            ),
            isLeaf: true,
            treeOnly: true,
            adminOnly: true
          },
          MenuNode.getDivider('cfg-div2', false),
          {
            key: RoutePath.SET_BACKEND,
            title: 'Application',
            path: RoutePath.SET_BACKEND,
            adminOnly: true,
            component: (
              <Suspense fallback={<Spinner />}>
                <ApplicationCfg />
              </Suspense>
            ),
            isLeaf: true,
          },
          {
            key: RoutePath.USERMANAGEMENT,
            title: 'Users',
            path: RoutePath.USERMANAGEMENT,
            adminOnly: true,
            component: (
              <Suspense fallback={<Spinner />}>
                <Management />
              </Suspense>
            ),
            isLeaf: true,
          },
        ],
      },
      {
        key: RoutePath.TOOLS,
        title: 'Tools',
        path: RoutePath.TOOLS,
        isLeaf: false,
        children: [
          {
            key: RoutePath.ALERTING,
            title: 'Alerting',
            path: RoutePath.ALERTING,
            component: (
              <Suspense fallback={<Spinner />}>
                <Alerting />
              </Suspense>
            ),
            isLeaf: true,
          },
          {
            key: RoutePath.EVENTS,
            title: 'CFG Tracker',
            path: RoutePath.EVENTS,
            component: (
              <Suspense fallback={<Spinner />}>
                <CFGTracker />
              </Suspense>
            ),
            isLeaf: true,
          },
          {
            key: RoutePath.REPORTER,
            title: 'Reporter',
            path: RoutePath.REPORTER,
            notInProduction: false,
            component: (
              <Suspense fallback={<Spinner />}>
                <Reporter />
              </Suspense>
            ),
            isLeaf: true,
          },
          {
            key: RoutePath.EXPORTER,
            title: 'Exporter',
            path: RoutePath.EXPORTER,
            component: (
              <Suspense fallback={<Spinner />}>
                <Exporter />
              </Suspense>
            ),
            isLeaf: true,
          },
          {
            key: RoutePath.TAGS,
            title: 'Tags',
            path: RoutePath.TAGS,
            component: (
              <Suspense fallback={<Spinner />}>
                <Tag />
              </Suspense>
            ),
            isLeaf: true,
          },
          {
            key: RoutePath.PUBLIC_API,
            title: <div
              onClick={(e) =>
                Modal.info({
                  title: 'Public API',
                  icon: null,
                  width: 'fit-content',
                  content: (
                    <div>
                      External documentation for our Public API is available at {' '}
                      <a href='/openapi.html' target='_blank'>
                        Public API Documentation
                      </a>.
                    </div>
                  ),
                })
              }
            >
              Public API
            </div>,
            isLeaf: true,
          },
        ],
      },
      {
        key: RoutePath.HELP,
        title: 'Help',
        path: RoutePath.HELP,
        children: [
          {
            key: RoutePath.DOCUMENTATION,
            title: <div
              onClick={(e) =>
                Modal.info({
                  title: 'Documentation',
                  icon: null,
                  width: 'fit-content',
                  content: (
                    <div>
                      External documentation is available on our website at{' '}
                      <a href='https://xormon.com/documentation-ng.php' target='_blank'>
                        https://xormon.com/documentation-ng.php
                      </a>.
                    </div>
                  ),
                })
              }
            >
              Documentation
            </div>,
            isLeaf: true,
          },
          {
            key: RoutePath.SUPPORT,
            title: 'Support',
            titleText: 'Support',
            path: RoutePath.SUPPORT,
            component: (
              <Suspense fallback={<Spinner />}>
                <Support />
              </Suspense>
            ),
            isLeaf: true,
          },
          {
            key: RoutePath.UPGRADE,
            title: 'Upgrade',
            path: RoutePath.UPGRADE,
            adminOnly: true,
            component: (
              <Suspense fallback={<Spinner />}>
                <UpgradeApp />
              </Suspense>
            ),
            isLeaf: true,
            notOnDocker: true
          },
          {
            key: RoutePath.SET_LOGS,
            title: 'Logs',
            path: RoutePath.SET_LOGS,
            adminOnly: true,
            isLeaf: false,
            children: [],
          },
          {
            key: 'feedback',
            title: <div
              onClick={(e) =>
                Modal.info({
                  title: 'Feedback',
                  icon: null,
                  width: 'fit-content',
                  content: (
                    <div>
                      Use this link{' '}
                      <a href='https://xormon.com/contact-ng.php#feedback' target='_blank'>
                        xormon.com/contact-ng.php#feedback
                      </a>{' '}
                      if you want to pass us any feedback.
                      <br />
                      Thanks.
                    </div>
                  ),
                })
              }
            >
              Feedback
            </div>,
            isLeaf: true,
          },
          {
            key: RoutePath.LICENSE,
            title: <LicenseBadge offset={[3, 0]} license={true} hostcfg={false} >License</LicenseBadge>,
            titleText: 'License',
            path: RoutePath.LICENSE,
            component: (
              <Suspense fallback={<Spinner />}>
                <License />
              </Suspense>
            ),
            adminOnly: true,
            isLeaf: true,
          },
          {
            key: RoutePath.ABOUT,
            title: 'About',
            path: RoutePath.ABOUT,
            component: (
              <Suspense fallback={<Spinner />}>
                <About />
              </Suspense>
            ),
            isLeaf: true,
          },
          MenuNode.getDivider('help-div', true),
          {
            key: RoutePath.SET_SELF_MONITORING,
            title: 'Self Monitoring',
            path: RoutePath.SET_SELF_MONITORING,
            component: <BasePage url={API_URL.SELF_MONITORING} />,
            isLeaf: true,
            adminOnly: true
          },
        ]
      },
      {
        key: RoutePath.PLAYGROUND,
        title: 'Playground',
        path: RoutePath.PLAYGROUND,
        notInDemo: true,
        notInProduction: true,
        children: [
          {
            key: 'plotly-pie',
            title: 'Pie',
            path: '/pie',
            component: (
              <Suspense fallback={<Spinner />}>
                <Pie />
              </Suspense>
            ),
            isLeaf: true,
          },
          {
            key: 'plotly-plot',
            title: 'Plot',
            path: '/plot',
            component: (
              <Suspense fallback={<Spinner />}>
                <Plot />
              </Suspense>
            ),
            isLeaf: true,
          },
          {
            key: 'plotly-sunburst',
            title: 'Sunburst',
            path: '/sunburst',
            component: (
              <Suspense fallback={<Spinner />}>
                <Sunburst />
              </Suspense>
            ),
            isLeaf: true,
          },
        ],
      },
    ],
  },
  {
    key: 'user',
    icon: <UserOutlined />,
    children: [
      {
        key: 'userName',
        title: '',
        disabled: true,
        isLeaf: true,
      },
      {
        key: 'user-div',
        divider: true,
        isLeaf: true,
      },
      {
        key: RoutePath.PROFILE,
        title: 'Profile',
        path: RoutePath.PROFILE,
        component: <Suspense fallback={<Spinner />}><ProfileTabs /></Suspense>,
        isLeaf: true,
      },
      {
        key: RoutePath.LOGOUT,
        title: 'Logout',
        path: RoutePath.LOGOUT,
        isLeaf: true,
      },
    ],
  },
];
