import { Modal, ModalProps } from "antd";
import React, { FC, useEffect, useState } from "react";
import { GLOB } from "src/util/Glob";
import './changeLog.less';
import { CHANGES } from "./changes";

function tokenizeVersion(version: string) {
  if (!version) return null;
  return version?.split(/[.|-]/);
}

/**
 * Checks if versionToCheck is newer than referenceVersion
 * @param versionToCheck
 * @param referenceVersion
 * @returns
 */
// differs from publicUrl.isNewerVersion
function isNewerVersion(versionToCheck: string, referenceVersion: string) {
  if (!referenceVersion) return true;
  if (!versionToCheck) return false;

  const tokenizedReference = tokenizeVersion(referenceVersion);
  const tokenizedCheck = tokenizeVersion(versionToCheck);

  // keep as string in future case of '2.3a-45', use natural sort
  for (let i = 0; i < tokenizedCheck.length; i++) {
    const current = tokenizedCheck[i];
    const ref = tokenizedReference[i];
    if (ref == null) return false;
    const cmp = GLOB.naturalSort(current, ref);
    if (cmp > 0) return true;
    if (cmp < 0) return false;
  }

  return false;
}

interface Version {
  version: string;
}

const ChangeLog: FC<Version> = props => {
  const [versions, setVersions] = useState<typeof CHANGES>([]);

  useEffect(() => {
    setVersions(CHANGES.filter(c => isNewerVersion(c.version, props.version)));
  }, [props.version]);

  return versions.map(c => ({ ...c.content, key: String(c.version) }) as React.ReactElement);
}

const LAST_DISPLAYED_CHANGELOG = "lastDisplayedChangelog";
const NEW = "What's new";
const LAST = "Release Note";
export const ChangeLogModal: FC<ModalProps & { timestamp: number }> = ({ timestamp, ...props }) => {
  const [title, setTitle] = useState('');

  useEffect(() => {
    const lastAvailableLog = CHANGES[0].version;
    const lastDisplayedVersion = JSON.parse(localStorage.getItem(LAST_DISPLAYED_CHANGELOG));
    if (GLOB.userInfo?.configuration?.showReleaseNotes
      && isNewerVersion(lastAvailableLog, GLOB.userInfo?.lastLoggedVersion) // per user after release
      && (lastAvailableLog !== lastDisplayedVersion || !isNewerVersion(lastAvailableLog, GLOB.getBackendInfo().backend.version)) // per browser before release and once after
    ) {
      localStorage.setItem(LAST_DISPLAYED_CHANGELOG, JSON.stringify(lastAvailableLog));
      setTitle(NEW);
    }
  }, [GLOB.userInfo?.lastLoggedVersion]);

  useEffect(() => {
    if (timestamp) setTitle(LAST);
  }, [timestamp]);

  return <Modal {...props} title={props.title || title} open={!!title} className="xm-changelog-modal"
    afterClose={() => GLOB.userInfo = { ...GLOB.userInfo, lastLoggedVersion: CHANGES[0].version }} onCancel={() => setTitle('')}
    cancelText="Close" okButtonProps={{ style: { display: 'none' } }}
  >
    <ChangeLog version={title === NEW ? GLOB.userInfo?.lastLoggedVersion : CHANGES[1].version} />
    <br />
    {title === LAST && <div>View all release notes on Xormon's <a href="https://xormon.com/announcements.php" target="_blank">announcements page</a>.</div>}
  </Modal>;
}

export function showChangeLog(version?: string) {
  GLOB.setState(prev => ({ ...prev, changeLogModalTimestamp: Date.now() }));
}